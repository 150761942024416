.form-control {
  font-size: 1rem;
}
.standard-form {
  label {
    color: $black;
    font-family: $headings-font-family;
  }
  fieldset legend {
    color: $secondary;
    font-size: 1rem;
    text-align: center;
  }
  .form-group {
    position: relative;
    margin-bottom: 1rem;
    .form-group-inner {
      border-radius: $border-radius;
      background-color: $light;
      padding: 0.75rem;
    }
    hr {
      border-top-color: $white;
    }
    .num {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: $dark;
      color: $white;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      left: 0.75rem;
    }
    .instructions {
      font-size: 0.75rem;
    }
  }
}
.standard-form-nums {
  .form-group {
    padding: 0.75rem 0.75rem 0.75rem 2.75rem;
  }
}

.required-info {
  margin: 0rem 0rem 1rem 0rem;
  font-size: 0.75rem;
}
.required {
  color: $danger;
}

.from-control-micro {
  display: inline-block;
  width: 60px;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: right;
}
.micro-addon {
  font-size: 1.5rem;
  margin-left: 1rem;
}

.from-control-xl-micro {
  display: inline-block;
  width: 120px;
  font-size: 2.25rem;
}
.xl-micro-addon {
  font-size: 1.5rem;
  margin-left: 0.5rem;
}
// specials
.checkbox-container {
  border-top: 1px solid $white;
  label {
    position: relative;
    padding: 1rem 0 1rem 3rem;
    margin-bottom: 0;
    .icon-wrap {
      position: absolute;
      margin-left: -2.5rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
  .checkbox {
    border-bottom: 1px solid $white;
    transition: 0.3s;
    &:hover {
      background-color: $white;
    }
  }
  input[type="checkbox"] {
    opacity: 0;
    width: 0px;
    position: absolute;

    + label {
      display: block;
    }
    &:checked {
      + label {
        // color: $white;
        // background: $primary;
      }
    }
    &:focus {
      + label {
        box-shadow: 0 0px 0px rgb(0, 0, 0, 0);
        background-color: $dark;
        color: $white;
      }
    }
  }
}

.form-control-stub {
  width: 120px;
  margin: 0 auto;
}

.radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;

  .form-check {
    padding: 0;
  }
  .form-check-inline {
    margin: 0;
    .form-check-label {
      color: $dark;
      border: 0.125rem solid $white;
      border-radius: 0.625rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  input[type="radio"] {
    opacity: 0;
    width: 0rem;
    visibility: hidden;
    + label {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
      background-color: $white;
      font-size: 1rem;
      min-width: 2.5rem;
      padding: 0 0.5rem;
      height: 3rem;
      border-radius: $border-radius;
      color: $dark;
    }

    &:hover {
      + label {
        background-color: rgb(6, 94, 184, 0.25);
        color: $black;
      }
    }

    &:checked {
      + label {
        color: $white;
        background: $primary;
      }
      &:hover {
        + label {
          background-color: $primary;
        }
      }
    }
    &:focus {
      + label {
        box-shadow: 0 0rem 0.5rem $primary;
      }
    }
  }
}
.radio-group-sm {
  input[type="radio"] {
    + label {
      height: 2rem;
      min-width: 2rem;
      border-radius: $border-radius !important;
      padding: 0;
    }
  }
}
.radio-group-hydration {
  gap: 0.25rem;
  input[type="radio"] {
    + label {
      border: 1px solid $dark !important;
      .icon-wrap {
        display: none;
        font-size:1rem;
      }
    }
    &:checked {
      + label {
        .icon-wrap {
          display: block;
        }
      }
    }
  }

  .form-check:nth-child(1) input[type="radio"] {
    + label {
      background-color: $urine-1;
    }
    &:checked {
      + label {
        background-color: $urine-1;
      }
      &:hover {
        + label {
          background-color: $urine-1;
        }
      }
    }
  }

  .form-check:nth-child(2) input[type="radio"] {
    + label {
      background-color: $urine-2;
    }
    &:checked {
      + label {
        background-color: $urine-2;
      }
      &:hover {
        + label {
          background-color: $urine-2;
        }
      }
    }
  }

  .form-check:nth-child(3) input[type="radio"] {
    + label {
      background-color: $urine-3;
    }
    &:checked {
      + label {
        background-color: $urine-3;
      }
      &:hover {
        + label {
          background-color: $urine-3;
        }
      }
    }
  }

  .form-check:nth-child(4) input[type="radio"] {
    + label {
      background-color: $urine-4;
    }
    &:checked {
      + label {
        background-color: $urine-4;
      }
      &:hover {
        + label {
          background-color: $urine-4;
        }
      }
    }
  }

  .form-check:nth-child(5) input[type="radio"] {
    + label {
      background-color: $urine-5;
    }
    &:checked {
      + label {
        background-color: $urine-5;
      }
      &:hover {
        + label {
          background-color: $urine-5;
        }
      }
    }
  }

  .form-check:nth-child(6) input[type="radio"] {
    + label {
      background-color: $urine-6;
    }
    &:checked {
      + label {
        background-color: $urine-6;
      }
      &:hover {
        + label {
          background-color: $urine-6;
        }
      }
    }
  }

  .form-check:nth-child(7) input[type="radio"] {
    + label {
      background-color: $urine-7;
    }
    &:checked {
      + label {
        background-color: $urine-7;
      }
      &:hover {
        + label {
          background-color: $urine-7;
        }
      }
    }
  }

  .form-check:nth-child(8) input[type="radio"] {
    + label {
      background-color: $urine-8;
    }
    &:checked {
      + label {
        background-color: $urine-8;
      }
      &:hover {
        + label {
          background-color: $urine-8;
        }
      }
    }
  }
}
.radio-group-text {
  .form-check-inline .form-check-label {
    display: flex;
    flex-direction: column;
    span {
      font-weight: normal;
      font-size: 0.625rem;
      display: block;
      color: #999;
      line-height: 0.625rem;
    }
  }

  input[type="radio"]:checked:hover + label {
    background-color: #123b1d;
    border: none;
    span {
      color: $white;
    }
  }

  input[type="radio"]:checked + label {
    color: #fff;
    background: #123b1d;
    border: none;
    span {
      color: $white;
    }
  }
}
.radio-group-text.chosen {
  .form-check-inline .form-check-label {
    color: #ccc;
    span {
      color: #ccc;
    }
  }

  input[type="radio"]:checked:hover + label {
    background-color: #123b1d;
    border: none;
    span {
      color: $white;
    }
  }

  input[type="radio"]:checked + label {
    color: #fff;
    background: #123b1d;
    border: none;
    span {
      color: $white;
    }
  }
}

.label-lg {
  font-size: 1.125rem;
  font-family: $headings-font-family;
}

$difficulty1: #156e00;
$difficulty2: #229317;
$difficulty3: #f0a143;
$difficulty4: #f04343;
$difficulty5: #b40000;

$difficulty-colors: (
  1: $difficulty1,
  2: $difficulty2,
  3: $difficulty3,
  4: $difficulty4,
  5: $difficulty5,
);

@each $color, $value in $difficulty-colors {
  .text-difficulty-#{$color} {
    color: rgba($value, 1) !important;
  }
  .text-wellbeing-#{$color} {
    color: rgba($value, 1) !important;
  }
  .bg-symptom-#{$color} {
    background-color: rgba($value, 1) !important;
  }
}

.icon-form-indicator{
  font-size:2rem;
  position: relative;
  display: inline-block;
  .sub-icon{
    position: absolute;
    right:-5px;
    font-size:0.625rem;
  }
  .sub-icon-top{
    top:0;
  }
  .sub-icon-bottom{
    bottom:10px
  }
}
.up-down-scale{
  display: flex;
  justify-content: space-between;
  .up-down-total{
    font-family: $headings-font-family;
    flex:1;
    text-align: center;
  }
  .up-down-total-subtext{
    font-size:0.625rem;
  }
}