.login{
    padding-top:50px;
    h2{
        text-align: center;
    }
    .logo{
        display: block;
        margin:30px auto;
    }
    .form-group{
        margin:10px 0;
    }
}