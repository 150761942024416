.btn {
  font-family: $headings-font-family;
  font-weight: bold;
  text-transform: uppercase;
  .icon-wrap{
    margin-right:5px;
  }
}
.btn-primary{
  color:$dark;
}

.card-body{
  .btn{
    padding:0.25rem 1rem;
  }
}

.btn-icon-only{
  .icon-wrap{
    margin-right:0;
  }
}
.btn-outline-primary {
  border-width: 2px;
}

.btn-lg{
  padding:0.5rem 1rem;
}
@each $color, $value in $theme-colors {
  .btn-transparent-#{$color} {
    @include button-variant(rgba($value, 0.8), rgba($value, 0.8));
  }
}
.btn-white {
  @include button-variant($white, $white, $dark, 
  $white, $white, $dark,
  $primary,
  $primary,
  $dark,
  $gray-500,
  $gray-500,
  $white);
}


.btn-lighten {
  @include button-variant(rgba($white,0.2), rgba($white,0), $white, 
  rgba($white,0.3), rgba($white,0), $white,
  $primary,
  $primary,
  $dark,
  $gray-500,
  $gray-500,
  $white);
}
.btn-wide{
  padding-left:2rem !important;
  padding-right:2rem !important;
}
.card-primary{
  .btn-white{
    color:$primary;
  }
}
