.add-to-homescreen{
    background:$primary;
    color:$white;
    position: fixed;
    width: 100vw;
    height:100vh;
    top:0;
    left:0;
    z-index: 9999;
    background-repeat: no-repeat;
    background-size: 90vw;
    background-position: center;
    
    .masthead{
        padding:1.5rem 2rem 0.5rem 2rem;
        font-size:1.25rem;
        line-height: 1.25;
        font-weight: 700;
        div.strapline{
            font-size:1rem;
            font-weight: 300;
        }
    }
    hr{
        border-color:$white;
    }
    .instructions{
        box-shadow:0px -20px 50px 20px rgba(0, 0, 0, 0.5);
        background:$dark;
        position: absolute;
        bottom:0;
        left:0;
        width:100vw;
        .instructions-content{
            padding:1rem 2rem;
            .share-icon{
                position: relative;
                top:-5px;
            }
            .subtext{
                font-size:0.75rem;
            }
        }

        ol {
            text-align:left;
            margin: 1rem 0;
            padding-left: 0.1rem;
            list-style-type: none;
          }
        ol > li {
            margin-bottom: 1rem;
            margin-left: 2.25rem;
            position: relative;
            counter-increment: customlistcounter;
            p:last-child {
              margin-bottom: 3rem;
            }
          }
          ol > li:before {
          content: counter(customlistcounter) " ";
          font-weight: bold;
          float: left;
          width: 24px;
          height: 24px;
          line-height: 25px;
          border-radius: 50%;
          font-weight: bold;
          color: $dark;
          text-align: center;
          background-color: $white;
          margin-left: -1.5rem;
          left: -0.75rem;
          position: absolute;
          }
    }
}

.background-container {
    background-position: top center;
    height: 100%;
    background-repeat: no-repeat;
}

.add-to-homescreen.iphone .background-container{
    background-image: url('/assets/img/iphone-app-launch.jpg');
    background-size: 85vw;
}
.add-to-homescreen.ipad .background-container {
    background-image: url('/assets/img/ipad-app-launch-02.jpg');
}

.add-to-homescreen.android .background-container {
    background-image: url('/assets/img/android-app-launch.jpg');
    background-size: 88vw;
}


.add-to-homescreen.ipad .masthead {
    padding:2rem 4rem 0.5rem 4rem;
    font-size:2.5rem;
    line-height: 3rem;
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

    .add-to-homescreen.ipad .background-container {
        background-size: 110%;
        background-position: top right 2rem;
    }

}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .add-to-homescreen.ipad .background-container {
        background-size: 150%;
        background-position: top right 2rem;
    }
}

@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1366px) 
and (orientation : landscape) {

    .add-to-homescreen.ipad .background-container {
        background-size: 110%;
        background-position: top right 2rem;
    }

}

@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1366px) 
and (orientation : portrait) {
    .add-to-homescreen.ipad .background-container {
        background-size: 180%;
        background-position: top right 2rem;
    }
}