// media queries
@include media-breakpoint-up(lg) {

}

@include media-breakpoint-only(lg) {
  .container-main {
    padding-right: 20px !important;
  }
}
@include media-breakpoint-only(lg) {
  .container-main {
    padding-right: 20px !important;
  }
}
