.msg-details {
  padding: 0 1rem 2rem 1rem;
  font-size: 0.75rem;
}
.chat-input-container {
  background-color: #f7f7f7;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.chat-input-container.hfn {
  animation-name: slideup;
  animation-delay: 0.1s;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.chat-input-container {
  .addon.btn {
    padding:3px 5px;
    bottom:0px;
  }
}

@keyframes slideup {
  0% {
    bottom: 0px;
    opacity: 0;
  }
  100% {
    bottom: 65px;
    opacity: 1;
  }
}

// media queries
@include media-breakpoint-up(md) {
  .chat-input-container {
    position: relative;
    bottom: auto;
    margin-top: 65px;
    textarea {
      height: 200px;
    }
  }
}

// Ask a Healthcare Professional Chat Input box position on iPhone's X and above

/* iPhone 12 Pro Max - 2778x1284 pixels at 458ppi */
@media only screen and (device-width: 428px) and (device-height: 926px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  @keyframes slideup {
    100% {
      bottom: 91px;
      opacity: 1;
    }
  }
}

/* iPhone 12 AND iPhone 12 Pro - 2532x1170 pixels at 460ppi */
@media only screen and (device-width: 390px) and (device-height: 844px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  @keyframes slideup {
    100% {
      bottom: 91px;
      opacity: 1;
    }
  }
}

/* iPhone 12 Mini - 2340x1080 pixels at 476ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  @keyframes slideup {
    100% {
      bottom: 91px;
      opacity: 1;
    }
  }
}

/* iPhone 11 Pro Max AND iPhone Xs Max - 2688x1242px at 458ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  @keyframes slideup {
    100% {
      bottom: 91px;
      opacity: 1;
    }
  }
}

/* iPhone 11 Pro AND iPhone X AND iPhone Xs - 2436x1125px at 458ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  @keyframes slideup {
    100% {
      bottom: 91px;
      opacity: 1;
    }
  }
}

/* iPhone 11 AND iPhone Xr - 1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  @keyframes slideup {
    100% {
      bottom: 91px;
      opacity: 1;
    }
  }
}

/* iPad Air 2020 - 2360x1640px at 264ppi */
@media only screen and (device-width: 820px) and (device-height: 1180px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  @keyframes slideup {
    100% {
      bottom: 91px;
      opacity: 1;
    }
  }
}
