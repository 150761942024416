.navbar-footer {
  background-color: $light;
  color: $dark;
  border-top: 1px solid #D7DFE0;
  padding: 0 0 0.4rem 0;
  box-shadow: 0 -2px 2px 0px rgba(0, 0, 0, 0.1);
  margin: 0;
}
.navbar.fixed-bottom .navbar-nav > li > a > .icon-wrap {
  font-size: 1.5rem;
}
.navbar-footer .d-flex {
  width: 100%;
}
.navbar-footer .navbar-nav {
  width: 100%;
}
.navbar-footer .nav-item {
  width: 20%;
  display: inline-block;
}
.navbar-footer .nav-item .nav-link {
  margin: 0 5px;
  text-align: center;
  color: $dark;
  border-top: 2px solid transparent;
  position: relative;
  .nav-label {
    font-family: $headings-font-family;
    font-size: 0.625rem;
    display: block;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    color:$dark;
    text-transform: uppercase;
  }
}
.navbar-footer .nav-item.active .nav-link {
  color: $primary;
  .nav-label{
    font-weight: bold;
  }
}

.has-footer-nav {
  padding-bottom: 65px;
}

// media queries
@include media-breakpoint-up(lg) {
  .navbar-footer {
    display: none;
  }
}

// iPhone bottom menu extra depth

/* iPhone 12 Pro Max - 2778x1284 pixels at 458ppi */
@media only screen and (device-width: 428px) and (device-height: 926px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav {
    padding-bottom: 91px;
  }
}

/* iPhone 12 AND iPhone 12 Pro - 2532x1170 pixels at 460ppi */
@media only screen and (device-width: 390px) and (device-height: 844px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav {
    padding-bottom: 91px;
  }
}

/* iPhone 12 Mini - 2340x1080 pixels at 476ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav {
    padding-bottom: 91px;
  }
}

/* iPhone 11 Pro Max AND iPhone Xs Max - 2688x1242px at 458ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav {
    padding-bottom: 91px;
  }
}

/* iPhone 11 Pro AND iPhone X AND iPhone Xs - 2436x1125px at 458ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav {
    padding-bottom: 91px;
  }
}

/* iPhone 11 AND iPhone Xr - 1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav {
    padding-bottom: 91px;
  }
}

/* iPad Air 2020 - 2360x1640px at 264ppi */
@media only screen and (device-width: 820px) and (device-height: 1180px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav {
    padding-bottom: 91px;
  }
}
