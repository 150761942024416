.loading-spinner-wrapper{
    position: fixed;
    width:100vw;
    height:100vh;
    background-color: $white;
    top:0;
    left:0;
    z-index: 5;
  }
  .loading-spinner-inline{
    position: absolute;
    width:100%;
    height:100vh;
    background-color: $white;
    top:0;
    left:0;
    z-index: 5;
  }
  .loading-spinner-sm{
    background-color: transparent;
    width:16px;
    height:16px;

    position: relative;
    .lds-ring {
      width:16px !important;
      height:16px !important;
      margin-top:-8px !important;
      margin-left:-8px !important;
      div {
        width:16px;
        height:16px;
        border-width: 3px;
      }
    }

  }
  .lds-msg{
    display: inline-block;
    position: absolute;
    width: 300px;
    height: 64px;
    top:40%;
    left:50%;
    margin-left:-150px;
    margin-top:64px;
    text-align: left;
  }
  .lds-ring {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    top:40%;
    left:50%;
    margin-top:-32px;
    margin-left:-32px;
  }
  .loading-spinner-inline{
    .lds-ring {
      top:10%;
    }
    .lds-msg{
      top:10%;
    }
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $primary;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }