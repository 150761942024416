.task-list{
    list-style: none;
    margin: 0;
    padding: 0;
    color: $dark;
    font-size: 0.875rem;
    li{
       
    }
    li > a{
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        flex-flow: row nowrap;
        justify-content: flex-start;
        padding-bottom: 10px;
        transition: 0.3s;
    }
    li > .flex-wrap{
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        flex-flow: row nowrap !important;
        justify-content: flex-start;
        padding-bottom: 10px;
    }
    li > a:hover{
        background-color:$light;
        text-decoration:none;
    }
    .num{
        background-color:$light;
        margin-right:5px;
        padding:6px 12px;
        min-width: 45px;
        max-width: 45px;
        text-align: center;
        border-radius: $border-radius;
        font-size:1.5rem;
        .icon-wrap{
            font-size:1.125rem;
        }
    }
    .task{
        color:$black;
        background-color:$light;
        border-radius: $border-radius;
        padding:6px;
        flex-grow:1;
        display: flex;
        position: relative;

        align-items: center;
        .label{
            text-transform: uppercase;
            min-width:24px;
            display:inline-block;
        }
        .title{
            padding-right:20px;
            .small{
                display:block;
                margin-top:-3px;
                font-size:0.625rem;
            }
        }
        .status{
            position: absolute;
            right:10px;
            color: $primary;        
        }
    }
    li.current{
        .num, .task{
        }
    }
    li.complete{
        .task{
           
        }
    }
}

.task-list {
    transition: 0.3s;
}

.task-list-stage-1 li:hover .task, .task-list-stage-2 li:hover .task, .task-list-stage-3 li:hover .task, .task-list-stage-4 li:hover .task {
    background-color: #D4DDDE;
    text-decoration: none;
}



.task-list-resources{
    .task{
        
        padding:6px 20px;
    }
   li > a:hover{
        background-color:transparent;
        text-decoration:none;
    }
}

.task-list-resources a .num .task-list-resources a .task {
    transition: 0.3s;
  }



  .task-list-resources a:hover .num, .task-list-resources a:hover .task {
      background-color: #D4DDDE;
  }
  
  .task-list-resources a:active .num, .task-list-resources a:active .task {
    background-color: $primary;
    color: $white;
  }
  
  .task-list-resources a:active .task .status {
    color: $white;
  }

.task-list-goals{
    li{
        border-bottom: 1px solid $light;
    }
    a {
        padding-top: 10px;
    }
    .num, .task{
        background-color: transparent;
    }
    .title {
        .subtitle{
            font-size:0.625rem;
            color:$dark;
        }
    }
}
.task-list-goal-info{

    .num{
        background-color:$dark;
        color:$white;
        display: inline-block;
            width:20px;
            height:20px;
            border-radius: 50%;
            font-size:.75rem;
            line-height: 20px;
            text-align:center;
            min-width:auto;
            padding:0;
            margin: 7px 5px;;
    }
    .title p{
        font-size:.875rem;
        color:$dark;
        padding:.5rem 0;
    }
}