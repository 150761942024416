
.cc-window {
    background-color: $dark!important;
    font-size: 0.875rem!important;
    box-shadow: inset 0px 2px 5px 0px rgba(0,0,0,0.25);
    font-family: $font-family-base !important;
  }
  
  .cc-btn {
    border-radius: $border-radius!important;
    color: $primary!important;
    font-weight: 300!important;
    font-family: $headings-font-family !important;
  
  &:hover {
    background-color: #ececec!important;
  }
  }
  .cc-revoke{
    display:none !important;
    font-family: $headings-font-family;
  }