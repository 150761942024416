.fade-in {
    animation: fadeIn ease-in-out 1s;
    -webkit-animation: fadeIn ease-in-out 1s;
    -moz-animation: fadeIn ease-in-out 1s;
    -o-animation: fadeIn ease-in-out 1s;
    -ms-animation: fadeIn ease-in-out 1s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}

.animate-500{
  animation-duration: 0.5s !important;

}
.animate-250{
  animation-duration: 0.5s !important;

}