// FA
@import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";

// bootstrap
@import "node_modules/bootstrap/scss/functions";
@import "styles/bootstrap/bootstrap-variables";
@import "node_modules/bootstrap/scss/bootstrap";

//inline vars
$sidebar-width: 340px;
$urine-1: #fdfbf7;
$urine-2: #f8f5d6;
$urine-3: #f3eda4;
$urine-4: #efe985;
$urine-5: #ece46e;
$urine-6: #efce5f;
$urine-7: #d3af56;
$urine-8: #c29d51;

// UI
@import "styles/_accessibility";
@import "styles/_general";
@import "styles/_buttons";
@import "styles/forms";
@import "styles/_navbar";
@import "styles/_footer-nav";
@import "styles/_sidebar";
@import "styles/_footer";
@import "styles/_animations";
@import "styles/aside-menu";
@import "styles/add-to-homescreen";
@import "styles/older-browsers";
@import "styles/cookie-consent";
@import "styles/loading-spinner";
@import "styles/tracker-list";
@import "styles/header-bar";
@import "styles/media-queries";
@import "styles/helpers";
@import "styles/_task-list";
@import "styles/_login";
@import "styles/_forum-posts";
@import "styles/_forum-list";
@import "styles/_chat";
@import "styles/_speech-bubble";
@import "styles/tracker-list";
@import "styles/date-picker";
@import "styles/resources";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.bg-lighten {
  background-color: rgba($white, 0.1) !important;
}

h3.title-xl {
  font-size: 1.5rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  .lighter {
    font-weight: normal;
  }
}

.welcome {
  font-size: 0.875rem;
}
//////
///
/// .
.container-inner {
  padding-left: var(--bs-gutter-x);
  padding-right: var(--bs-gutter-x);
}
// cards
.card-header {
  h3 {
    font-size: 0.875rem;
    margin: 0;
    .icon-wrap {
      margin-right: 5px;
    }
  }
  .more-link {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0%, -50%);
    line-height: 1;
  }
}
.card-subtitle {
  font-size: 0.5rem;
  font-weight: normal;
}
.card-title-u {
  border-bottom: 1px solid $dark;
  padding-bottom: 0.25rem;
}
.bg-dark {
  .card-masthead {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: .75rem;
  }
  .card-total {
    color: rgba($white, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .card-info {
    color: rgba($white, 0.6);
    font-size:0.5rem;
  }
}
.card-total {
  font-size: 1.125rem;
  font-family: $headings-font-family;
  color: rgba($dark, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 0px;
}
.card-total-lg {
  font-size: 1.5rem;
}
.card-total-light {
  color: $white;
  border-bottom: 1px solid $white;
}
.card-total-nb {
  border: none !important;
}
.card-total-inline {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius;
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
}
.card-total-title {
  font-size: 0.75rem;
}
.card-info {
  padding: 0;
  font-size: 0.625rem;
  color: rgba($dark, 0.6);
}
.card-icon-sm {
  font-size: 0.5rem;
}
.card-progress,
.stat {
  .card-progress-title {
    font-family: $headings-font-family;
    padding: 0;
    font-size: 0.5rem;
    margin-top: 3px;
    text-transform: uppercase;
  }
  .progress {
    margin: 1px;
    height: 10px;
    background-color: $white;
  }
  .progress-bar {
    border-radius: $progress-border-radius;
  }
  .progress-sm {
    margin: 1px;
    height: 4px;
    background-color: $white;
  }
}
.card-title-lg {
  font-size: 1.125rem;
}
.card-text {
  font-size: 0.625rem;
}
.card-text-module {
  font-size: 0.625rem;
  color: rgba($white, 0.64);
}
.card-subtitle-lg {
  font-size: 0.75rem;
}
.card-strapline {
  font-family: $headings-font-family;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  .icon-wrap {
    margin-right: 5px;
  }
}
.card-icon {
  font-size: 1.125rem;
}
.card-icon-lg {
  font-size: 3rem;
}
.card {
  hr {
    margin: 0.5rem 0;
  }
}
.card-footer {
  a {
    color: $dark;
    font-family: $headings-font-family;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.75rem;
  }
}

.card-img-top-fh {
  object-fit: cover;
  max-height: 260px;
  object-position: top;
}
.card-btns {
  display: flex;
  gap: 5px;
  justify-content: center;
  .btn {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.25rem 0.5rem;
    .icon-wrap {
      margin: 0;
    }
  }
}
.btn-link-nd {
  text-decoration: none;
}
// days
.stat {
  .days-indicator {
    display: grid;
    grid-template-columns: repeat(auto-fill, 35px);
    justify-content: space-between;
    grid-gap: 10px;
    font-family: $headings-font-family;
  }
}
.days-indicator {
  display: flex;
  justify-content: space-between;
  font-family: $headings-font-family;
  .day-label {
    font-size: 0.625rem;
  }
  .day {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: auto;
    flex-grow: 0;
    width: 35px;
  }
  .day-status {
    width: 17px;
    height: 6px;
    border-radius: $border-radius;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    .dot {
      background-color: $primary;
      width: 2px;
      height: 2px;
      display: block;
      border-radius: 50%;
    }
  }
}
.days-indicator-sm {
  .day-label {
    font-size: 0.5rem;
    margin-top: 2px;
  }

  .day-status {
    height: 4px;
  }
}
.days-indicator-tight {
  justify-content: center;
  gap: 10px;
}
.days-indicator-lg,
.days-indicator-md {
  .day-label {
    font-size: 0.875rem;
  }
  .day-status-lg {
    width: 40px;
    height: 40px;
    border-radius: $border-radius;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .icon-wrap {
      line-height: 1rem;
      font-size: 1.25rem;
    }
  }
  .day-status-circle {
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }
  .day-status-lg.day-status-col {
    display: flex;
    flex-direction: row;
    > div {
      margin: 0 2px;
    }
    .icon-wrap {
      line-height: 1.25rem;
      font-size: 0.75rem;
    }
  }
  .day-status-title {
    font-size: 0.875rem;
  }
  .day-status-title-sm {
    font-size: 0.75rem;
    .icon-wrap {
      font-size: 0.5rem;
      position: relative;
      top: -1px;
    }
  }
  .day-status-subtitle {
    font-size: 0.5rem;
    text-transform: uppercase;
  }
  .day-status {
    width: 40px;
    height: 10px;
    .dot {
      width: 4px;
      height: 4px;
    }
  }
}
.days-indicator-md {
  .day-label {
    font-size: 1rem;
  }
}
.days-indicator-white {
  .day-status {
    .dot {
      background-color: $white;
    }
  }
  .day-status {
    border: 1px solid $white;
    background-color: transparent;
  }
}

// progress
.progress-outline {
  outline: 1px solid white;
}
.progress-tracker-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-right: 2px solid $dark;
  box-sizing: unset;
}
.progress-tracker-icon-sm {
  border: 0;
  font-size: 0.5rem;
  height: 8px;
  width: 8px;
  margin-left: -10px;
  margin-top: 1px;
}
.target-pointers {
  position: relative;
  height: 50px;
}
.target-pointer {
  position: absolute;
  top: 0px;
  font-size: 0.5rem;
  font-family: $headings-font-family;
  width: 50px;
  margin-left: -25px;
  text-align: center;
  .target-pointer-icon {
    font-size: 0.625rem;
  }
  .target-pointer-label {
    margin-top: -5px;
  }
}

.masthead-title {
  font-size: 1.125rem;
  text-transform: uppercase;
}
.masthead-info {
  font-size: 0.625rem;
}

// subnav
.subnav {
  background-color: $dark;
  display: flex;
  color: $white;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  h2 {
    flex-grow: 1;
    text-align: center;
    margin: 0;
  }
  .prev,
  .next {
    width: 50px;
    a {
      color: $white;
    }
  }
  .prev {
    margin-left: 10px;
  }
  .next {
    margin-right: 10px;
    text-align: right;
  }
}

// stats
.stat {
  border-radius: $border-radius;
  background-color: $white;
  padding: 0.25rem;
  text-align: center;
  .stat-title {
    font-family: $headings-font-family;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    .icon-wrap {
      margin-right: 5px;
      font-size: 0.625rem;
    }
    .stat-title-sm {
      font-family: $headings-font-family;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
    }
  }
  .stat-data {
    font-size: 0.625rem;
  }
  .stat-icon-lg {
    font-size: 2rem;
    line-height: 1;
  }
  .stat-data-lg {
    font-size: 2.375rem;
    font-family: $headings-font-family;
    line-height: 1;
  }
  .stat-title-lg {
    font-size: 0.75rem;
    font-family: $headings-font-family;
    text-align: left;
    line-height: 1;
  }
  .stat-title-subtext {
    font-size: 0.75rem;
    font-family: $font-family-base;
  }
}
.stat-flex {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 5px 10px;
  gap: 5px;
}
.stat-flex-stretch {
  .stat {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
  }
}
.stat-intensity {
  width: 200px;
  .stat-data-lg {
    width: 30px;
  }
  .stat-title-lg {
    flex: 1;
    line-height: 0.9;
  }
}

// awards
.award-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.award {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $dark;
  color: $white;
  font-family: $headings-font-family;
  .award-data {
    display: flex;
    align-items: center;
    justify-content: center;
    .award-icon {
      font-size: 2rem;
      line-height: 1;
    }
    .award-total {
      font-size: 2.375rem;
      line-height: 1;
    }
  }
  .award-info {
    font-size: 0.625rem;
    text-align: center;
    line-height: 1;
  }
}
.award-square {
  border-radius: 1rem;
}

// link list
.link-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    a {
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      border-radius: $border-radius;
      padding: 0.75rem 1rem;
      color: $dark;
      text-decoration: none;
      font-size: 0.75rem;
      .link-label {
        flex: 1;
      }
      span {
        margin-right: 10px;
      }
      span:last-child {
        margin-right: 0;
      }
      .icon-wrap {
        font-size: 0.875rem;
      }
      .badge {
        font-family: $headings-font-family;
        .icon-wrap {
          font-size: 0.625rem;
        }
      }
    }
    margin: 0.5rem 0;
  }
}

// entries-table
table.entries-table {
  width: 100%;
  box-sizing: unset;
  thead {
    tr {
      th {
        background-color: transparent;
        font-family: $headings-font-family;
        font-size: 0.75rem;
        font-weight: 300;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      td {
        margin-bottom: 1rem;
        font-family: $headings-font-family;
        font-size: 0.75rem;
        font-weight: 300;
        text-align: center;
        padding: 5px 0;
        vertical-align: middle;
        .et-cell {
          background-color: $white;
          display: flex;
          min-height: 50px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .et-cell-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .icon-wrap {
              font-size: 1.125rem;
              margin-right: 5px;
            }
          }
          .et-total {
            font-size: 1.125rem;
            display: block;
            line-height: 1;
          }
          .icon-wrap {
            font-size: 0.875rem;
          }
        }
      }
      td:first-child {
        .et-cell {
          border-radius: $border-radius 0 0 $border-radius;
        }
      }
      td:last-child {
        .et-cell {
          border-radius: 0 $border-radius $border-radius 0;
        }
      }
    }
  }
}
table.entries-table-alt {
  thead {
    tr {
      th {
        padding-bottom: 5px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-family: $font-family-base;
        padding: 0;
        .et-cell {
          min-height: 30px;
        }
      }
    }
    tr:nth-child(even) {
      td {
        .et-cell {
          background: none;
        }
      }
    }
  }
}

// tabs
/*
.nav-tabs-alt {
  .nav-tabs {

    border-bottom: none;
    position: relative;
    ::after {
      position: absolute;
      width: 100%;
      height: 5px;
      background-color: $white;
      border-radius: $border-radius;
      left: 0;
      bottom: -5px;
      content: " ";
      display: block;
      z-index: 1;
    }

    .nav-link:hover,
    .nav-link:focus {
      isolation: auto !important;
      border: none;
    }
    .active {
      border-bottom: none;
      position: relative;
      margin-bottom: 0;
      ::after {
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: $primary;
        border-radius: $border-radius;
        left: 0;
        bottom: -5px;
        content: " ";
        display: block;
        z-index: 10;
      }
    }
  }
}*/

.nav-tabs-alt {
  .nav-tabs {
    border-bottom: 5px solid $white;
    margin-bottom: -5px;
    font-family: $headings-font-family;
    .active {
      margin-bottom: -5px;
      border-bottom: 5px solid $primary;
    }
  }
}
.nav-tabs {
  border-bottom: 5px solid $white;
  margin-bottom: -5px;
  font-family: $headings-font-family;
  font-size:0.75rem;
  font-weight:bold;
  text-transform: uppercase;
  .active {
    margin-bottom: -5px;
    border-bottom: 5px solid $secondary;
  }
}
// general
.video-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .video-link {
    background-color: $white;
    display: flex;
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius;
    align-items: center;
    text-decoration: none;
    color: $dark;
    font-size: 0.75rem;
    flex: 1;
    .icon-wrap {
      margin-right: 10px;
      font-size: 1.25rem;
    }
  }
}

//
.container-content {
  h3 {
    font-size: 1.125rem;
  }
  font-size: 0.875rem;
  hr {
    height: 2px;
    background-color: $light;
    border: none;
    opacity: 1;
  }
}
.circle {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 50%;
}
.circle-num {
  display: inline-flex;
  background: $light;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-family: $font-family-base;
  align-items: center;
  font-size: 0.625rem;
  border-radius: 50%;
}
.circle-lighten {
  background-color: rgba($white, 0.2);
}
.circle-sm {
  width: 24px;
  height: 24px;
  font-size: 0.875rem;
}
.icon-circle {
  width: 24px;
  height: 24px;
  font-size: 0.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.icon-circle-lg {
  width: 30px;
  height: 30px;
  font-size: 1.25rem;
}
// sliders
.temp-slider {
  max-width: 320px;
  margin: 0 auto;
  .ngx-slider .ngx-slider-bar {
    background: none;
    height: 5px;
  }
  .ngx-slider .ngx-slider-selection {
  }
  .ngx-slider .ngx-slider-pointer {
    width: 8px;
    height: 30px;
    border: 1px solid $dark;
    top: -13px;
    bottom: 0;
    background-color: $white;
    border-radius: $border-radius;
  }

  .ngx-slider .ngx-slider-pointer:after {
    display: none;
  }
  .ngx-slider .ngx-slider-bubble {
    bottom: 14px;
  }
  .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    color: orange;
  }
  .ngx-slider .ngx-slider-tick {
    width: 30px;
    height: 10px;
    margin-left: -12px;
    border-radius: 0;
    background: red;
    top: 0px;
    border-radius: $border-radius;
  }
  .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background: orange;
  }
}
.slider-custom-ticks {
  display: flex;
  justify-content: space-between;
  margin: -10px -10px 0 -10px;
  div.slider-custom-tick {
    max-width: 70px;
    font-size: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .custom-tick-icons {
      text-align: center;
    }
    .icon-emoji {
      font-size: 1.125rem;
    }
  }
  div.slider-custom-tick:first-child {
    text-align: left;
    align-items: start;
  }
  div.slider-custom-tick:last-child {
    text-align: right;
    align-items: end;
  }
}
.slider-custom-indicators {
  display: flex;
  justify-content: space-between;
  margin: 0px -10px 0 -10px;
  .indicator {
    font-size: 0.625rem;
  }
  .indicator:first-child {
  }
  .indicator:last-child {
    text-align: right;
  }
}

.temp-slider-sm {
  .ngx-slider .ngx-slider-tick {
    width: 20px;
    height: 10px;
    margin-left: -6px;
  }
}
// submit confirm
.submit-confirm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > .icon-wrap {
    font-size: 10rem;
    color: $dark-green;
  }
  .entry-details {
    background-color: $light;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    > div {
      margin: 0.5rem;
      text-align: center;
    }
    .label {
      font-weight: bold;
    }
  }
}
.aside {
  font-size: 0.625rem;
}

// forms

.form-group-container {
  background-color: $light;
  border-radius: $border-radius;
  padding: 1rem;
  .instructions {
    font-size: 0.75rem;
    text-align: center;
  }
  .form-group > label {
    color: $dark;
    font-size: 0.875rem;
  }
}

//
.module-score-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.module-score {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $headings-font-family;
  .module-score-title {
    font-size: 0.625rem;
  }
  .module-score-subtitle {
    font-size: 0.5rem;
  }
  .module-score-stat {
    font-size: 0.875rem;
    position: relative;
    display: flex;
    align-items: center;
  }
  .module-score-stat-change {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    left: 16px;
    font-size: 0.5625em;
    .icon-wrap {
      font-size: 0.5rem;
      margin-right: 2px;
    }
  }
  .module-score-stat-change-inline {
    position: relative;
    top: unset;
    transform: unset;
    left: unset;
    display: flex;
    margin-left: 5px;
  }
}

// tables
.tracker-table {
  width: 100%;
  th,
  td {
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 300;
    padding: 0.25rem 0.25rem;

    .dot {
      background-color: $white;
      width: 4px;
      height: 4px;
      display: inline-block;
      border-radius: 50%;
    }
  }
  td {
    border-bottom: 1px solid rgba($white, 0.2);
  }
  .tracker-table-label {
    font-size: 0.75rem;
    font-family: $headings-font-family;
  }
  .table-tracker-target {
    .icon-wrap {
      font-size: 0.5rem;
    }
    font-size: 0.625rem;
    color: rgba($white, 0.75);
  }
}

.tracker-table-sm {
  th {
    font-size: 0.5rem;
  }
}
// weeks nav
.weeks-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .prevnext {
    min-width: 50px;
    button {
      color: $secondary;
      font-size: 1.5rem;
    }
  }
  .weeks-nav-title {
    font-size: 0.625rem;
    h4 {
      font-size: 1.5rem;
      margin: 0 0 0.25rem 0;
      padding: 0;
      line-height: 1;
    }
  }
}
// tracker stats
.tracker-stats {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  .card-total {
    margin: 0;
    max-width: 105px;
  }
}
// dashboard
.db-widget {
  padding: 1rem;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: top;
  .db-widget-content {
    background-color: rgba($dark, 0.8);
    width: 50%;
    padding: 1rem;
    border-radius: 0.5rem;
    color: $white;
    backdrop-filter: blur(3px);
    > div {
      font-family: $headings-font-family;
      font-size: 0.625rem;
    }

    .db-widget-content-item {
      position: relative;
      h3 {
        font-size: 0.75rem;

        .icon-wrap {
          font-size: 0.825rem;
        }
      }
    }
    .db-widget-content-title {
      font-size: 0.5rem;
      text-transform: uppercase;
      margin: 5px 0;
    }
    hr {
      border-color: rgba(255, 255, 255, 0.1);
      opacity: 1;
      margin: 0.75rem 0;
    }
  }
}

// stacked icons
.stacked-icons {
  position: relative;
  .icon-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .icon-wrap-shrink{
    top: 1%;
    left: 1%;
    width: 98%;
    height: 98%;
  }
  .icon-wrap:last-child {
    position: relative;
    top: unset;
    left: unset;
    width: unset;
    height: unset;
  }
}


// duotone icon colors
.duo-primary-dark{
  --fa-primary-color: #{$dark}; 
  --fa-secondary-color: #{$primary}; 
  --fa-secondary-opacity: 1;
}