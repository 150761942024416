
.navbar-main {
    min-height: 60px;

    .back-link{
      position: absolute;
      top:12px;
      left:10px;
       color:$dark;
       font-size:1.125rem;
       line-height:1.125rem;
       display: inline;
       padding:0.5rem 1rem 0.5rem 0.9rem;
       border-radius: $border-radius;
       &:hover {
         background-color: rgb(255,255,255,0.1);
       }
       &:active {
         background-color: rgb(255,255,255,0.3)!important;
         transition: 0s;
       }
   }

    .navbar-brand {
      font-family: $headings-font-family;
      font-weight: normal;
      display: inline-block;
      top: 0px;
      padding:0;
      text-align: center;
      img {
        height: 36px;margin-top: 10px;
      }
    }
    h1{
      color:$dark;
      font-size: 1.25rem;
      line-height: 60px;
      margin:0;
      padding:0;
      position: relative;
      top:0px;
    }
    .btn-nav-menu{
      font-size:1.25rem;
      color:$dark;
      border: none;
      background-color: transparent;
    }
    .nhs-logo{
      position: relative;
      top:5px;
      img{
        height:17px;
        width: 42px;
      }
    }
  }

  // media queries
@include media-breakpoint-up(lg) {
    
  .navbar-main {
    margin-top:1rem;
    border-radius: $border-radius $border-radius 0 0;
    .nhs-logo, .btn-nav-menu{
      display:none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-main {    
  .back-link{
    left:2px;
}
  }
}
