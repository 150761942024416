html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}
.fh {
  min-height: 100vh;
}
.fh-nav {
  min-height: calc(100vh - 60px);
}
.fh-nav-subnav {
  min-height: calc(100vh - 151px);
}
.fh-nav-subnav-stagenav {
  min-height: calc(100vh - 197px);
}
.content-container {
  padding: 20px;

}
.container-narrow{
  max-width: 780px;
  margin:0 auto;
}
.bg-dark {
  background:$dark;
  color: $white;
  a {
    color: $white;
  }
  .form-floating label{
    color:$dark;
  }
  hr {
    border-color: rgba($white, 0.5);
  }
}
.bg-grey {
  background-color: #ddd;
}
.bg-menu-bg {
  background-color: $dark;
}
.text-menu-bg {
  color: $dark;
}
.text-light-grey {
  color: #ddd;
}
.divider {
  border-top-width: 1px;
}
.divider-light {
  border-color: rgba(255, 255, 255, 0.5);
}
.drop-shadow {
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.1);
}

a .drop-shadow:active {
    box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
    transform: translate(3px, 3px);
  }

  .pointer{
    cursor: pointer;
  }
// extra bgs
@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: rgba($value, 1) !important;
  }
}
@each $color, $value in $theme-colors {
  .bg-#{$color}-dark {
    background-color: rgba(darken($value, 14), 1);
  }
}
@each $color, $value in $theme-colors {
  .bg-#{$color}-darker {
    background-color: rgba(darken($value, 24), 1);
  }
}

// form addons
.inner-addon {
  position: relative;
}
.inner-addon .addon {
  position: absolute;
  padding: 10px;
  z-index: 20;
}
.inner-addon .addon.btn {
  padding: 5px;
  margin: 5px;
  text-align: center;
}
.left-addon .addon {
  left: 0px;
}
.right-addon .addon {
  right: 0px;
}
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.nmh{
  min-height: auto!important;
}
.icon-xl{
  font-size:3rem;
}

.icon-button {
margin-top: -0.2rem;
padding: 0.2rem 0.5rem;
display: inline-block;
border-radius: $border-radius;
transition: 0.3s;
  &:hover {
    background-color: rgb(255,255,255,0.1);
  }
  &:active {
    background-color: rgb(255,255,255,0.2);
  }
}

.cc-window {
  background-color: $dark!important;
  font-size: 0.875rem!important;
  font-family: 'Frutiger'!important;
  box-shadow: inset 0px 2px 5px 0px rgba(0,0,0,0.25);
}

.cc-btn {
  border-radius: $border-radius!important;
  color: $primary!important;
  font-weight: 300!important;

&:hover {
  background-color: #ececec!important;
}
}
.cc-revoke{
  display:none !important;
}
// media queries
@include media-breakpoint-up(md) {
  .content-wrap {
    margin: 1rem auto;
    background-color: $white;
    border-radius: $border-radius;
    min-height:100vh;
    padding:.5rem 1.5rem;
  }
  .content-wrap-np{
    padding:0;

  }
  router-outlet ~ * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}