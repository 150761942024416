.resource-nav-item{
    text-align: center;
    display: block;
    color: $dark;
    font-size:0.875rem; 
     &:hover {
       text-decoration: none;
     }
    .nav-item-title{
      margin-top:10px;
      display: block;
    }
    .icon-wrap{
      color:$primary;
      margin-left:5px;
    }
  }
  
  
  .resource-index-images {
    overflow: hidden;            /* clip the excess when child gets bigger than parent */
    border-radius: $border-radius;
  }
  .resource-index-images img {
    display: block;
    transition: all .3s;   /* smoother zoom */
    opacity: 1;
  }
  .resource-nav-item:hover .resource-index-images img {
    transform: scale(1.1);
    transform-origin: 50% 50%;
    opacity: 0.75;
  }
  
  .resource-masthead-img{
    min-height:160px;
  }
  .container-resource-page{

  
        line-height: 1.6rem;
  
        p {
          margin-bottom: 1.6rem;
        }
  
        ul li {
          margin-bottom: 1.6rem;
          position: relative;
          list-style: none;
        }
  
        ul li:before {
          content: "";
      display: inline-block;
      margin-left: -1.6rem;
      margin-top: 0.4rem;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      background-color: $primary;
        }
  
        ol {
          text-align:left;
          margin: 0;
          padding-left: 0.1rem;
          list-style-type: none;
        }
  
        ol > li {
          margin-bottom: 1.5rem;
          margin-left: 2.25rem;
          position: relative;
          counter-increment: customlistcounter;
          p:last-child {
            margin-bottom: 3rem;
          }
        }
        ol > li:before {
        content: counter(customlistcounter) " ";
        font-weight: bold;
        float: left;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        font-weight: bold;
        color: $white;
        text-align: center;
        background-color: $primary;
        margin-left: -1.5rem;
        left: -0.75rem;
        position: absolute;
        }
  
      h2.sm-title {
        font-size: 1.5rem;
      }
      h2.sm-title a.prev, h2.sm-title a.next {
        font-size: 1rem;
        padding: 0.25rem;
        position: absolute;
        top:0px;
      }
      h2.has-prev-next{
        position: relative;
        padding-left:20px;
        padding-right:20px;
        .prev{
          left:0px;
        }
        .next{
          right:0px;
        }
      }
      h4 {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
  
      h5 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1rem;
        line-height: 1.6rem;
        margin-bottom: 0.5rem;
      }
  
      hr {
        margin: 2.5rem 0;
      }
  
      hr:last-child {
        margin: 2.5rem 0 0 0;
      }
  
      img {
        max-height: 400px;
        max-width: 100%;
        margin: 1rem auto 0 auto;
        display: block;
      }
  
      img.full{
        max-height: none;
      }
  
      .prev-next {
        margin: 2rem 0 1rem 0;
        border-top: 1px solid rgba(35, 31, 32, 0.1);
        padding-top: 1rem;
          .col {
            padding-left: 0px;
            padding-right: 0px;
          }
      }
  
      a.prev, a.next {
        color: $primary;
        border-radius: $border-radius;
        display: inline-block;
        padding: 0.5rem 0.75rem;
        transition: 0.3s;
        &:hover {
          color:$dark;
          background-color: $light;
        }
        &:active {
          color:$white;
          background-color: $primary;
          transition: 0s
        }
      }
  
      .top-tips {
        background-color: #FFFAD8;
        border: 1px solid #C0D2D6;
        border-radius: $border-radius;
        padding: 0rem 0.75rem 0.75rem 0.75rem;
        margin-top: 3.5rem;
        text-align: center;
        
          h4 {
            text-align: center;
            background-color: $dark;
            color: $white;
            display: block;
            padding: 0.5rem 1rem;
            margin: -1.5rem auto 1rem auto;
            width: 10rem;
            border-radius: $border-radius;
            box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.1);
          }
  
          svg {
            width:30px;
            fill: $white;
            margin: 0 0.5rem 0 0;
          }
  
          ol {
            text-align:left;
            margin: 0;
            padding-left: 0.1rem;
            list-style-type: none;
          }
  
          ol > li {
            margin-bottom: 1.5rem;
            margin-left: 2.25rem;
            position: relative;
            counter-increment: customlistcounter;
          }
          ol > li:before {
          content: counter(customlistcounter) " ";
          font-weight: bold;
          float: left;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          font-weight: bold;
          color: $white;
          text-align: center;
          background-color: $dark;
          margin-left: -1.5rem;
          left: -0.75rem;
          position: absolute;
          }
  
          p:last-child {
            margin-bottom: 0.5rem;
          }
      }
      .speech-bubble {
        position: relative;
        background: $light;
        border-radius: $border-radius;
        font-size: 1rem;
        color: $dark;
        padding: 1rem;
      }
      
      .speech-bubble:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 25%;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-top-color: $light;
        border-bottom: 0;
        border-left: 0;
        margin-left: -7.5px;
        margin-bottom: -15px;
      }
  
  ul.traffic-lights li {
    margin-bottom: 1.6rem;
    position: relative;
    list-style: none;
    margin-left: 0.5rem;
  }
  
  ul.traffic-lights li:before {
    content: "";
      display: inline-block;
      margin-left: -3rem;
      margin-top: 0.4rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      position: absolute;
      left: 0;
  }
  
  ul.traffic-lights li.red:before {
    background-color: #C60000;
  }
  ul.traffic-lights li.amber:before {
    background-color: #CE9E00;
  }
  ul.traffic-lights li.green:before {
    background-color: #00844C;
  }
  
  ul.energy {
    margin-top: 1rem;
  }
  ul.energy li {
    margin-left: 0;
  }
  
  ul.energy li span {
    top: 0.4rem;
    left: -2.5rem;
    position: absolute;
    width: 30px;
    height: 30px;
    svg {
        width:30px;
        fill: $primary;
    }
  }
  ul.energy li:before {
    background-color: rgb(255,255,255,0);
    margin-left: 0;
    left: 0;
  }
  
  h5.positive, h5.negative {
    display: block;
    margin-bottom: 1rem;
    svg {
      width:24px;
      margin: -0.2rem 0.5rem 0 0;
    }
  }
  
  h5.positive {
    svg {
      fill: #00844C;
    }
  }
  
  h5.negative {
    svg {
      fill: #C60000;
    }
  }
  
      .click-to-show{
        margin: 0.75rem 0;
        .info{
        display:none;
        }
        .clicker{
        border-radius: $border-radius;
        color:$primary;
        font-size: 1rem;
        font-weight: bold;
        cursor:pointer;
        display: block;
        padding: 1rem;
        background-color: $light;
        transition: 0.3s;
        svg {
          width:30px;
          fill: $dark;
          margin: -0.2rem 1rem 0 0;
        }
        &:hover {
          background-color: #D4DDDE;
        }
          &:active {
            background-color: $primary;
            color: white;
            transition: 0s;
            svg {
              fill: $white;
            }
          }
          
        }
        .clicker:focus {
          background-color: $primary;
          color: white;
          svg {
            fill: $white;
          }
          }
        .clicker:focus ~ .info {
        display: block;
        padding: 1rem;
        }
       }
  }
  
  .internal-app {
    padding: 1rem;
  
    h4 {
      text-align: center;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid $dark;
      margin-bottom: 2rem;
    }
  
    .speech-bubble {
      background-color: $white;
      margin-bottom: 3rem;
    }
  
    .speech-bubble:after {
      border-top-color: $white;
    }
  
    .tool-options {
      background-color: $white;
      padding: 1rem;
      border-radius: $border-radius;
  
      label {
        position: relative;
        padding: 0.5rem 0 0.5rem 2.3rem;
        margin-bottom: 0;
          .icon-wrap {
            position: absolute;
            margin-left: -2.2rem;
          }
      }
    }
  
  
    .form-check {
      border-bottom: 1px solid $light;
      transition: 0.3s;
      &:hover {
        background-color: $light;
      }
  
    }
  
    
  
    .internal-title {
      padding: 0;
      margin: 0;
      display: block;
    }
  
  
    .checkbox-container  {
      background-color: $white;
      padding: 1rem;
      border-radius: $border-radius;
  
      label {
        position: relative;
        padding: 1rem 0 1rem 3rem;
        margin-bottom: 0;
          .icon-wrap {
            position: absolute;
            margin-left: -2.5rem;
          }
      }
      .checkbox {
        border-bottom: 1px solid $light;
        transition: 0.3s;
          &:hover {
          background-color: $light;
        }
      }
    }
  
  
    .checkbox-container input[type=checkbox] {
      position: absolute;
    }
  
  
    ol li {
      border-bottom: 1px solid #fff;
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
    }
  
    .btn {
      padding: 1rem;
      font-size: 1rem;
    }
  
    .input-group-text {
      background-color: #D4DDDE;
      color: $dark;
    }
  }
  
  .sleep-score-results .btn {
    margin-bottom: 0.5rem;
  }
  
  .sleep-score-results p {
    text-align: center;
  }
  
  @include media-breakpoint-down(md) {
  .img-container{
    margin:0 -15px;
    .rounded {
      border-radius: 0px!important;
    }
  
    .drop-shadow {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0)
    }
  }
  .container-resource-page{
    padding-left:5px;
    padding-right:5px;
    h2.sm-title {
      font-size: 1.125rem;
    }
    h2.sm-title a.prev, h2.sm-title a.next{
      margin-top: -0.2rem;
    }
  
    h4 {
      font-size: 1rem;
      font-weight: bold;
    }
  
    h5 {
      font-size: 0.875rem;
      font-weight: bold;
    }
  
    hr {
      margin: 2rem 0;
    }
  
    .top-tips, .important-alert {
      svg {
        width:24px;
        margin: 0 0.5rem 0 0;
      }
    }
  }
  }
  
  
  
  // Sleep form
  .sleep-score-form .form-group{
    border:none !important;
    > label{
      font-weight: bold;
    }
  }
  
  // MUST
  .must-form{
    .result-box{
      display:block;
      padding:1rem;
      background-color: $white;
      border-radius: $border-radius;
      font-size: 1rem;
      line-height: 1.3rem;
      min-height: 7rem;
      margin-bottom: 1rem;
      strong{
        display:block;
        margin:1rem;
      font-size:1.8rem !important;
      }
    }
  
  .must-row {
    border-bottom: 1px solid $white;
    padding-bottom: 1rem;
    line-height: 1rem;
  }
  
    .btn-group {
      .btn-primary {
        background-color: $dark;
        padding: 0.6rem 0.5rem 0.6rem 0.5rem;
        font-size: 0.875rem;
        color: #ccc;
        border-color: $white;
        &:hover {
          background-color: $black;
          color: $white;
        }
      }
      .btn-primary:not(:disabled):not(.disabled).active {
      background-color: $primary;
      color: $white;
      border-color: $white;
      }
  }
  }
  
  @media only screen and (max-width: 769px) {
    .must-form{
      .btn-group, .input-group {
        margin-top: 1rem;
      }
    }
    .internal-app {
      padding: 0.25rem;
  
        h4 {
          margin-bottom: 0.75rem;
        }
    }
  }
  
  .sleep-table {
    tr th {
      color: #fff;
      text-align: center;
      background-color: #005EB8;
      border-left: 2px solid #005EB8;
      border-right: 2px solid #005EB8;
      border-bottom: 2px solid #005EB8;
      padding: 9px 24px;
  }
    td{
      border-bottom: 2px solid #E8EDEE;
      border-left: 2px solid #E8EDEE;
      padding: 10px 20px;
      text-align: center;
      vertical-align: middle;
    }
    tr td:last-child{
      border-right:2px solid #E8EDEE;
    }
   
    td svg {
    height: 32px;
    line-height: 1.5;
    fill: #00A9CE;
    margin:10px 0;
  }
  }
  
  .logos{
    border:1px solid $light;
    border-radius: $border-radius;
    margin:0rem;
    .col-6{
      border:1px solid $light;
      align-items: center;
      justify-content: center;
      display: flex;
      img{
        margin:10px;
      }
    }
  }
  
  
  .important-alert {
    background-color: #FFD9D9;
    border: 1px solid #C0D2D6;
    border-radius: $border-radius;
    padding: 0rem 0.75rem 0.75rem 0.75rem;
    margin-top: 3.5rem;
    text-align: center;
    
      h4 {
        text-align: center;
        background-color: #C60000;
        color: $white;
        display: block;
        padding: 0.5rem 1rem;
        margin: -1.5rem auto 1rem auto;
        width: 12rem;
        border-radius: $border-radius;
        box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.1);
      }
  
      svg {
        width:30px;
        fill: $white;
        margin: -0.2rem 0.1rem 0 0;
      }
  
      p:last-child {
        margin-bottom: 0.5rem;
      }
  
      ul li:before {
        background-color: #C60000;
          }
        
          ol > li:before {
            background-color: #C60000;
          }
  }
  